//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-488:_6188,_3336,_6708,_9680,_3056,_2240,_3184,_9904;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-488')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-488', "_6188,_3336,_6708,_9680,_3056,_2240,_3184,_9904");
        }
      }catch (ex) {
        console.error(ex);
      }