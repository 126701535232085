import shopifyBangerDefaults from '../shopify-banger/config.js';

const mobileScreenWidth = 768;

const { ignoreFields } = shopifyBangerDefaults.Widgets.find((w) => w.name === 'FacetPanel');
const { baseFields: baseFitmentFields, extraFields: extraFitmentFields } =
  shopifyBangerDefaults.fitmentSearch;

const onItemsRendered = () => {
  const relatedItems = window.document.querySelector('.cm_related-container');

  if (relatedItems) {
    const sliderOptions = { items: 4, pagination: false, navigation: true, navigationText: ['<', '>'] };

    if (typeof window.owlCarousel !== 'undefined') {
      window.owlCarousel.init(sliderOptions, relatedItems);
    }
  }
};

export default {
  ...shopifyBangerDefaults,
  includes: ['shopify-banger'],
  Widgets: [
    ...shopifyBangerDefaults.Widgets.filter(
      (w) =>
        ![
          'CategoryFacetPanel',
          'SearchVehicleWidget',
          'SearchBoxMobile',
          'SearchBoxTop',
          'RelatedItems',
          'FacetDialog',
        ].includes(w.name),
    ),
    {
      name: 'CategoryFacetPanel',
      type: 'SearchPage',
      location: '#cm_collection-facet-panel',
      template: 'FacetPanelContainer',
      ignoreFields,
    },
    {
      name: 'FilterChips',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
    },
    {
      name: 'SearchBoxTop',
      type: 'SearchBoxDialogButton',
      location: {
        replace: '.header-wrap-stick .block-search .form-search',
        class: 'cm_search-box-root__dialog_open-button_container',
      },
      template: 'searchBox/dialogButtonAdaptable',
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      visibleIf: () => window.innerWidth > mobileScreenWidth,
      fields: baseFitmentFields,
    },
    {
      name: 'SearchVehicleWidget_mobile',
      type: 'VehicleWidget',
      visibleIf: () => window.innerWidth <= mobileScreenWidth,
      isAlwaysActive: true,
      fields: baseFitmentFields,
      columnBreakpoint: mobileScreenWidth,
    },
    {
      name: 'VehicleSpecs',
      type: 'VehicleWidget',
      fields: extraFitmentFields,
    },
    {
      name: 'HomeVehicleWidget_mobile',
      type: 'VehicleWidget',
      location: {
        replace: '#shopify-section-section_ymm_bar_mobile_qxUtnK',
      },
      template: 'fitmentSearch/collapsibleVehicleWidget',
      initCollapsed: true,
      isAlwaysColumnLayout: true,
    },
    {
      name: 'RelatedItems',
      location: {
        selector: 'body.template-product .related-product .container',
        class: 'cm_related-items',
      },
      template: 'product/relatedProducts',
      count: window.Convermax.productRecommendationsSettings?.limit || 6,
      useManualItems: true,
      onItemsRendered,
    },
    {
      name: 'FacetDialog',
      template: 'FacetDialogVehicleSpecs',
      ignoreFields,
    },
  ],
};
