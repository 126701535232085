//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-488:_1772,_8820,_6364,_964,_77,_968,_8412,_7760;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-488')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-488', "_1772,_8820,_6364,_964,_77,_968,_8412,_7760");
        }
      }catch (ex) {
        console.error(ex);
      }