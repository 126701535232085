
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedProductsRT () {
    function repeatItems1(items, itemsIndex) {
        return [items(function () {
                return _createElement('div', { 'className': 'col-md-4 col-sm-6 col-xs-6' }, _createElement('div', { 'className': 'grid-product-item product-item style-01 rows-space-40' + (window.Convermax.config.payBrightToken ? ' cm_paybright' : '') }, _createElement('div', { 'className': 'product-inner' }, _createElement('div', { 'className': 'product-thumb' }, _createElement('a', {
                    'className': 'thumb-link' + (this.image2 ? ' first-image' : ''),
                    'href': this.url
                }, _createElement('img', {
                    'className': 'img-responsive',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                })), this.image2 ? _createElement('div', {
                    'className': 'second-image',
                    'key': '549'
                }, _createElement('a', {
                    'href': this.url,
                    'className': 'thumb-link'
                }, _createElement('img', {
                    'className': 'img-responsive',
                    'src': this.imageOrDefault(this.resizeImage(this.image2)),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2')
                }))) : null, _createElement('div', { 'className': 'flash' }, !this.out_of_stock && this.on_sale ? _createElement('span', {
                    'className': 'onsale',
                    'key': '914'
                }, _createElement('span', {}, '-', Math.floor((1 - this.price / this.compare_at_price) * 100), '%')) : null, this.out_of_stock ? _createElement('span', {
                    'className': 'onsoldout',
                    'key': '1103'
                }, _createElement('span', {}, window.themeSettings.locales.sold_out)) : null, !this.out_of_stock && this.stock && (this.stock?.includes('Available') && !this.stock?.includes('In-stock Only')) ? _createElement('span', {
                    'className': 'onspecial',
                    'key': '1247'
                }, _createElement('span', {}, window.themeSettings.locales.special_order)) : null, window.Convermax.productIsNew(this.published_at) ? _createElement('span', {
                    'className': 'onnew',
                    'key': '1536'
                }, _createElement('span', {}, window.themeSettings.locales.new)) : null), _createElement('div', { 'className': 'group-button' }, _createElement('a', {
                    'data-id': this.handle,
                    'className': 'engoj_btn_quickview button engoc-btn-quickview'
                }, _createElement('i', { 'className': 'flaticon-magnifying-glass-browser fz-21' })), _createElement('div', {
                    'className': 'engoc-add-to-wishlist wishlist-hero-custom-button',
                    'data-wlh-id': this.id,
                    'data-wlh-link': this.url,
                    'data-wlh-variantid': this.variant_ids[0],
                    'data-wlh-price': this.price,
                    'data-wlh-name': this.title,
                    'data-wlh-image': this.image,
                    'data-wlh-mode': 'icon_only',
                    'data-wlh-view': 'Collection'
                }, _createElement('i', { 'className': 'flaticon-heart-shape-outline' })))), _createElement('div', { 'className': 'product-info equal-elem' }, _createElement('div', { 'className': 'cat-list' }, _createElement('a', Object.assign({}, {
                    'href': this.vendor_url,
                    'title': this.removeHTML(this.vendor)
                }, { dangerouslySetInnerHTML: { __html: this.vendor } }))), _createElement('h3', { 'className': 'product-name product_title' }, _createElement('a', Object.assign({}, { 'href': this.url }, { dangerouslySetInnerHTML: { __html: this.title } }))), _createElement('div', { 'className': 'product-info-inner' }, _createElement('span', { 'className': 'price' }, this.on_sale ? _createElement('span', {
                    'className': 'money del',
                    'key': '2796'
                }, this.formatPrice(this.compare_at_price)) : null, this.on_sale ? _createElement('span', {
                    'className': 'money ins',
                    'key': '2902'
                }, this.formatPrice(this.price)) : null, !this.on_sale ? _createElement('span', {
                    'className': 'money',
                    'key': '2997'
                }, this.formatPrice(this.price)) : null), !this.out_of_stock ? _createElement('div', {
                    'className': 'add-to-cart',
                    'key': '3106'
                }, _createElement('div', { 'className': 'add-to-cart-wrap azirspares-add-to-cart-wrap' }, this.variant_ids.length === 1 ? _createElement('form', {
                    'method': 'post',
                    'action': '/cart/add',
                    'key': '3244'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids
                }), _createElement('button', {
                    'type': 'submit',
                    'name': 'add',
                    'id': 'AddToCart' + this.id,
                    'className': 'enj-add-to-cart-btn engoc-btn-default'
                }, _createElement('i', { 'className': 'flaticon-online-shopping-cart' }))) : null, this.variant_ids.length > 1 ? _createElement('a', {
                    'href': this.url,
                    'key': '3642'
                }, _createElement('i', { 'className': 'flaticon-online-shopping-cart' })) : null)) : null), this.price ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'key': '3840'
                }) : null))));
            }, { count: undefined })];
    }
    return _createElement('div', { 'className': 'wrap-product' }, _createElement('h2', { 'className': 'product-grid-title' }, 'Related products'), _createElement.apply(this, [
        'div',
        { 'className': 'cm_related-container owl-products equal-container better-height d-flex row cmRepeater_items' },
        _map(this.items, repeatItems1.bind(this))
    ]));
}
        export const componentNames = []